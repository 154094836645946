// MessageBanner.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import '../../../src/css/MessageBanner.css';

type MessageType = 'error' | 'warning' | 'success' | 'info';

interface MessageProps {
    message: string;
    type: MessageType;
}


const MessageBanner: React.FC<MessageProps> = ({message, type}) => {
    return (
        <div className={`message-banner message-${type}`}>
            {message}
        </div>
    );
};

const MessageManager = (() => {
    let node: HTMLDivElement | null = null;

    const createNode = () => {
        node = document.createElement('div');
        document.body.appendChild(node);
    };

    const destroyNode = () => {
        if (node) {
            ReactDOM.unmountComponentAtNode(node);
            document.body.removeChild(node);
            node = null;
        }
    };

    const showMessage = (message: string, type: MessageType) => {
        if (!node) createNode();
        ReactDOM.render(<MessageBanner message={message} type={type}/>, node);

        setTimeout(() => {
            destroyNode();
        }, 3000);
    };

    return {
        error: (message: string) => showMessage(message, 'error'),
        warning: (message: string) => showMessage(message, 'warning'),
        success: (message: string) => showMessage(message, 'success'),
        info: (message: string) => showMessage(message, 'info'),
    };
})();

export default MessageManager;
