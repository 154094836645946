import React, {useState} from 'react';
import './App.css'
import ModuleInformation from "./Components/ModuleInformation";
import Rubrica from "./Components/Rubrica/Rubrica";
import Retroalimentacion from "./Components/Retroalimentacion";

function App() {
    const [rubrica, setRubrica] = useState([]);
    const [actividad, setActividad] = useState('');
    const [alumno, setAlumno] = useState('');
    const [asesor, setAsesor] = useState('');
    const [grupo, setGrupo] = useState('M0C4G43-172');
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    const [rubricaFormulario, setRubricaFormulario] = useState(
        new Array(17).fill(null).map((_, index) => ({id: `${index}`, level: ''}))
    );

    const setRubData = (crit: string, niv: string) => {
        setRubricaFormulario(prevItems => {
            return prevItems.map(item =>
                item.id === crit ? {...item, level: niv} : item
            );
        });
    };

    return (
        <div className="App">
            <nav>
                <div className="left-nav">
                    <span className="platform-label">Plataforma de Evaluación y Retroalimentación</span>
                </div>
                <div className="right-nav">
                    <span className="assistant-label">Asesor Virtual</span>
                    <input type="text" id="name" value={asesor} readOnly/>
                </div>
            </nav>

            <div className="flag-bar"></div>

            <main>
                <ModuleInformation
                    setActividad={setActividad}
                    setRubrica={setRubrica}
                    setAlumno={setAlumno}
                    setGrupo={setGrupo}
                    setAsesor={setAsesor}
                    grupo={grupo}
                />
                <Rubrica
                    rubrica={rubrica}
                    setRubData={setRubData}
                />
                <Retroalimentacion
                    mod={1}
                    act={actividad}
                    alumno={alumno}
                    asesor={asesor}
                    grupo={grupo}
                    rubrica_formulario={rubricaFormulario}
                    setErrorMsg={setErrorMsg}
                />

                {errorMsg && <span className="error-message">{errorMsg}</span>}
            </main>

            <footer>
                <p>Derechos de autor © 2024. Todos los derechos reservados.</p>
            </footer>
        </div>
    );
}

export default App;
