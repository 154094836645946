import React, {useState} from 'react';
import '../../css/LoginPopup.css';
import Button from "../Common/Button";

type LoginPopupProps = {
    isOpen: boolean;
    onClose: () => void;
    onLogin: (user: string, password: string) => void;
};

const LoginPopup: React.FC<LoginPopupProps> = ({isOpen, onClose, onLogin}) => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleLogin = (e: React.FormEvent) => {
        e.preventDefault();
        onLogin(username, password)
    };

    if (!isOpen) return null;

    return (
        <div className="login-popup">
            <div className="login-popup-content">
                <button className="close-button" onClick={onClose}>&times;</button>
                <h2>Login</h2>
                <form>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <Button
                        loading={false}
                        onClick={handleLogin}
                    >
                        {'Login'}
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default LoginPopup;
