import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/variables.css';
import reportWebVitals from './reportWebVitals';
import LandingPage from "./Components/LandingPage";
import App from "./App";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AppHeader from "./Components/AppLayout/AppHeader";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>

    <BrowserRouter>
        <AppHeader/>
        <Routes>
            <Route path='/' element={
                <div className="App"
                     style={{
                         alignContent: 'center'
                     }}
                >
                    <LandingPage/>
                </div>}/>
            <Route path='app' element={
                <div className="App"
                     style={{
                         alignContent: 'center'
                     }}
                >
                    <App/>
                </div>
            }/>
        </Routes>
    </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
