import React from 'react';
import '../css/LandingPage.css';

const LandingPage: React.FC = () => {

    return (
        <div className="LandingPage">
            <header className="LandingPage-header">
                <h1 translate="no" className="unselectable">Activity feedback</h1>

                <p>
                    Plataforma de Evaluación y Retroalimentación
                </p>
            </header>
            <footer className="LandingPage-footer">
                <p>Derechos de autor © 2024. Todos los derechos reservados.</p>
            </footer>
        </div>
    );
};

export default LandingPage;
