import React, { useRef } from 'react';
import '../../css/Button.css'; // Ensure this CSS file includes the spinner styles

interface ButtonProps {
    onClick: (e?: any) => void;
    loading: boolean;
    children: React.ReactNode;
    disabled?: boolean;
    primary?: boolean;
    ariaLabel?: string;
}

const Button: React.FC<ButtonProps> = ({ onClick, loading, children, disabled = false, primary = false, ariaLabel }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!loading && !disabled) {
            onClick(e);
        }
        buttonRef.current?.blur();
    };

    return (
        <button
            ref={buttonRef}
            className={`common-button ${primary ? 'primary' : ''}`}
            onClick={handleClick}
            disabled={loading || disabled}
            aria-label={ariaLabel || 'Button'}
        >
            {loading ? (
                <div className="spinner" aria-hidden="true"></div>
            ) : (
                children
            )}
        </button>
    );
};

export default Button;
