import React, { useState } from "react";
import '../../css/AppHeader.css'
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from "../Common/Button";
import { login } from "../../Util/http/Auth";
import LoginPopup from "../Auth/LoginPopup";

const Header = () => {
    const isLoggedIn = localStorage.getItem('user') !== null;
    const navigate = useNavigate();
    const location = useLocation();
    const [isPopupOpen, setPopupOpen] = useState(false);

    const handleLogoutClick = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate('/');
    };

    // Function to determine if the login/go to app button should be displayed
    const shouldShowLoginButton = () => {
        return !isLoggedIn || location.pathname === '/';
    };

    return (
        <>
            <LoginPopup isOpen={isPopupOpen}
                        onClose={() => setPopupOpen(false)}
                        onLogin={(user: string, password: string) => {
                            login(
                                user,
                                password,
                                () => {},
                                (logged) => {
                                    setPopupOpen(!logged)
                                },
                                navigate
                            )
                        }}
            />
            <header className="header">
                <Link to="/">
                    <img
                        src={'./logo.jpeg'}
                        height={'64px'}
                        alt={'Logo'}
                        style={{
                            cursor: 'pointer'
                        }}
                    />
                </Link>
                <div className="header-actions">
                    {shouldShowLoginButton() && (
                        <Button
                            onClick={() => {
                                if (isLoggedIn) {
                                    navigate('/app');
                                } else {
                                    setPopupOpen(true);
                                }
                            }}
                            children={isLoggedIn ? 'Go to app' : 'Login'}
                            loading={false}
                            primary={isLoggedIn}
                        />
                    )}
                    {isLoggedIn && (
                        <Button
                            onClick={handleLogoutClick}
                            children={'Logout'}
                            loading={false}
                        />
                    )}
                </div>
            </header>
        </>
    );
};

export default Header;
