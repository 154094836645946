import React, {useRef, useState} from 'react';
import {api} from "../Util/Const";
import '../css/Retroalimentacion.css';
import Button from "./Common/Button";
import MessageBanner from "./Common/MessageBanner";
import MDEditor from '@uiw/react-md-editor';


// Define types for props for better type-checking
interface RetroalimentacionProps {
    rubrica_formulario: any;
    mod: number; // Assuming these are identifiers, they should be typed as strings
    act: string;
    asesor: string;
    alumno: string;
    grupo: string;
    setErrorMsg: (message?: string) => void;
}

// Functional component for Retroalimentacion
const Retroalimentacion: React.FC<RetroalimentacionProps> = ({
                                                                 rubrica_formulario,
                                                                 mod,
                                                                 act,
                                                                 asesor,
                                                                 alumno,
                                                                 grupo,
                                                                 setErrorMsg
                                                             }) => {
    // State for storing evaluation and loading status
    const [evaluacion, setEvaluacion] = useState<string>('');
    const [urlDocument, seturlDocument] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    // Ref for the Markdown container
    const markdownRef = useRef<HTMLDivElement>(null);

    // Function to copy the rendered Markdown
    const copyMarkdown = () => {
        if (!markdownRef.current) return;

        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(markdownRef.current);
        selection?.removeAllRanges();
        selection?.addRange(range);

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                MessageBanner.success('Evaluación copiada')
                console.log('Copied Markdown content to clipboard');
            } else {
                console.log('Failed to copy Markdown content');
            }
        } catch (err) {
            console.error('Failed to copy Markdown content', err);
        }

        // Clear selection
        selection?.removeAllRanges();
    };

    const getEvaluation = async () => {
        if (loading) return;

        setLoading(true);
        setEvaluacion(''); // Clear previous evaluation
        const fmData = new FormData();
        fmData.append("text", JSON.stringify(rubrica_formulario));
        fmData.append("id_modulo", mod.toString());
        fmData.append("id_actividad", act);
        fmData.append("asesor", asesor);
        fmData.append("alumno", alumno);
        fmData.append("grupo", grupo);

        setErrorMsg(undefined);

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {'X-access-token-dev': localStorage.getItem('token') ?? ''},
            body: fmData,
        };

        try {
            const response = await fetch(`${api}/chat/generate_st`, requestOptions);
            if (!response.ok) {
                const errorMsg = response.status === 422 ? 'Se debe introducir el nombre del aspirante' :
                    response.status === 403 ? 'Debes iniciar sesión' :
                        `Error ${response.status}`;
                setErrorMsg(errorMsg);
                console.error('ERROR ' + response.status);
                setLoading(false);
                return;
            }

            const reader = response.body?.getReader();
            const decoder = new TextDecoder();
            let accumulatedText = '';

            const processStream = async () => {
                if (reader) {
                    let {done, value} = await reader.read();
                    while (!done) {
                        // Decode the stream chunk to text, ensuring partial characters are handled properly
                        const chunk = decoder.decode(value, {stream: true});
                        accumulatedText += chunk;

                        // Only process complete lines if your protocol involves line-separated JSON objects
                        const lines = accumulatedText.split('\n');
                        accumulatedText = lines.pop() || ''; // Keep the last incomplete line for the next chunk

                        lines.filter(line => line.trim()).forEach(line => {
                            try {
                                const json = JSON.parse(line);
                                if (json.url !== undefined) {
                                    seturlDocument(json.url)
                                }

                                // Append the content of the current chunk to the state
                                setEvaluacion(prev => `${prev}${json.content}`);
                            } catch (error) {
                                console.error('Error parsing JSON chunk', error);
                            }
                        });

                        // Read the next chunk
                        ({done, value} = await reader.read());
                    }

                    // Decode any remaining text that was not flushed (if the stream ends without a newline)
                    if (accumulatedText.length > 0) {
                        try {
                            const json = JSON.parse(accumulatedText);
                            setEvaluacion(prev => `${prev}${json.content}`);
                        } catch (error) {
                            console.error('Error parsing final JSON chunk', error);
                        }
                    }

                    setLoading(false);
                }
            };

            await processStream();
        } catch (error) {
            setErrorMsg('Ocurrió un error al generar la evaluación.');
            console.error('Fetch error:', error);
            setLoading(false);
        }
    };


    return (
        <section aria-labelledby="retroalimentacion-header" className="retroalimentacion">
            <h2 id="retroalimentacion-header">Generar Retroalimentación</h2>
            <Button
                onClick={getEvaluation}
                loading={loading}
                ariaLabel="Generar evaluación"
            >
                {loading ? 'Generando...' : 'Generar'}
            </Button>

            <div className="evaluation-result" ref={markdownRef}>
                {loading && (
                    <p>Generando evaluación, esto puede tardar unos segundos...</p>
                )}

                {/*<MDEditor*/}
                {/*    value={evaluacion}*/}
                {/*    onChange={(e) => (setEvaluacion(e ?? ''))}*/}
                {/*/>*/}
                <MDEditor.Markdown source={evaluacion}/>

                {/*<ReactMarkdown>{evaluacion}</ReactMarkdown>*/}
            </div>
            {
                evaluacion &&
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '1rem'}}>
                    <Button
                        onClick={copyMarkdown}
                        ariaLabel="Copiar contenido"
                        loading={false}
                    >
                        Copiar Contenido
                    </Button>

                    <Button
                        onClick={() => window.open(urlDocument)}
                        ariaLabel="Descargar documento"
                        loading={false}
                    >
                        Descargar documento
                    </Button>
                </div>
            }

        </section>
    );
};

export default Retroalimentacion;
