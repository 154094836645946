import React, {useEffect, useState} from 'react';
import {api} from "../Util/Const";
import '../css/ModuleInformation.css'
import {useNavigate} from "react-router-dom";

interface ModuleInformationProps {
    setRubrica: (rubrica: any) => void;
    setActividad: (actividad: any) => void;
    setAlumno: (alumno: string) => void;
    setGrupo: (grupo: string) => void;
    setAsesor: (asesor: string) => void;
    grupo: string;
}

export default function ModuleInformation(props: ModuleInformationProps) {
    const [activities, setActivities] = useState<{ id_activity: string, activity_name: string }[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getUserInfo = async () => {
            const requestOptions: RequestInit = {
                method: 'POST',
                headers: {'X-access-token-dev': localStorage.getItem('token') ?? ''},
            };
            const response = await fetch(`${api}/user/info`, requestOptions);
            if ([403, 404, 500, 401].includes(response.status)) {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                navigate('/');
                console.error('ERROR ' + response.status);
                return;
            }
            const data = await response.json();
            props.setGrupo(data['user_group'])
            props.setAsesor(`${data['name']} ${data['user_ap']} ${data['user_am']}`)
        };

        const getContent = async () => {
            const requestOptions: RequestInit = {
                method: 'GET',
                headers: {'X-access-token-dev': localStorage.getItem('token') ?? ''}
            };
            const response = await fetch(`${api}/module/1`, requestOptions);
            if ([403, 404, 500, 401].includes(response.status)) {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                navigate('/');
                console.error('ERROR ' + response.status);
                return;
            }
            const data = await response.json();
            setActivities(data.response);
            if (data.response.length > 0) {
                getRubricaContent(data.response[0].id_activity);
            }
        };

        getContent();
        getUserInfo()
// eslint-disable-next-line
    }, []);

    const getRubricaContent = async (id: string) => {
        props.setActividad(id);
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {'X-access-token-dev': localStorage.getItem('token') ?? ''}
        };
        const response = await fetch(`${api}/module/1/${id}`, requestOptions);
        if ([403, 404, 500, 401].includes(response.status)) {
            console.error('ERROR ' + response.status);
            return;
        }
        const data = await response.json();
        props.setRubrica(data);
    };

    return (
        <section className="module-info">
            <h2>Información del Módulo</h2>
            <div className="datagrid">
                <div className="grid-cell">
                    <label htmlFor="module">Módulo:</label>
                    <input type="text" id="module" value="Propedéutico" readOnly/>
                </div>
                <div className="grid-cell">
                    <label htmlFor="group">Grupo:</label>
                    <input type="text" id="group" value={props.grupo} readOnly/>
                </div>
                <div className="grid-cell">
                    <label htmlFor="activity">Actividad a Evaluar:</label>
                    <select id="activity" onChange={e => getRubricaContent(e.target.value)}>
                        {activities.map(activity => (
                            <option key={activity.id_activity} value={activity.id_activity}>
                                {activity.activity_name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="grid-cell">
                    <label htmlFor="student">Aspirante:</label>
                    <input type="text" id="student" defaultValue=""
                           onChange={e => props.setAlumno(e.target.value)}/>
                </div>
            </div>
        </section>
    );
}
