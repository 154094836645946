import React from 'react';
import Rubro from "./Rubro";
import '../../css/Criterio.css'


interface CriterioProps {
    data: any,
    title: string,
    setRubData: (e: any) => void // Assuming setRubData is a function that takes a RubroData object.
}

export default function Criterio({data, title, setRubData}: CriterioProps) {
    return (
        <section className="criterio-container">
            <h2 className="criterio-title">{title}</h2>
            <div className="rubros-container">
                {data && data.map((item: any, _: number) => (
                    <Rubro
                        key={item.id} // Replace 'item.id' with the actual identifier from your data
                        data={item}
                        rubro={title}
                        setRubData={setRubData}
                    />
                ))}
            </div>
        </section>
    );
};
