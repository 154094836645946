import React, {useEffect, useState} from 'react';
import '../../css/Rubro.css'

type RubroProps = {
    data: any,
    rubro: string,
    setRubData: (id: string, nv: string) => void
};
export default function Rubro({data, rubro, setRubData}: RubroProps) {
    const [selectedValue, setSelectedValue] = useState('');

    const handleRadioChange = ((nv: string) => {
        // console.log(data.id, nv);
        setSelectedValue(nv);
        if (rubro === 'PLAGIO') {
            setRubData(data.id, 'CAPACITADO');
        } else {
            setRubData(data.id, nv);
        }
    }); // added dependencies


    useEffect(() => {
        if (rubro !== 'PLAGIO') {
            handleRadioChange('EXPERTO')
        }
        // eslint-disable-next-line
    }, []);


    return (
        <div className="rubro-container">
            {['EXPERTO', 'CAPACITADO', 'ACEPTABLE', 'APRENDIZ', 'REQUIERE APOYO'].map((nv) => (
                <div key={nv} className="rubro-grid-cell">
                    {data.values[nv].text !== 'NaN' && (
                        <>
                            <div className="rubro-title">
                                {data.id === '0' && <span>{nv}</span>}
                            </div>
                            <input
                                type="radio"
                                id={`opcion_${data.id}_${rubro}_${nv}`}
                                name={`nueva_opcion_${data.id}_${rubro}`}
                                // required
                                onChange={() => handleRadioChange(nv)}
                                checked={selectedValue === nv}
                                onClick={()=>{
                                    if (selectedValue === nv){
                                        setSelectedValue('');
                                        setRubData(data.id, '');
                                    }
                                }}
                            />
                            <label htmlFor={`opcion_${data.id}_${rubro}_${nv}`}>
                                {data.values[nv].text}
                            </label>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};