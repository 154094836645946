import {api} from "./Common";

const handleResponse = (response: Response) => {
    if (!response.ok) throw new Error(response.statusText);
    if (response.status === 204) return '204';
    return response.json();
};

const handleError = (error: Error) => {
    if (error.message === 'Unauthorized') {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
    }
    console.log(error.message)
    // message.error(error.message);
    // MessageBanner.error(error.message)
};

export const fetchApi = (url: string, options?: RequestInit) =>
    fetch(url, options).then(handleResponse).catch(handleError);

export const login = (
    user: string,
    password: string,
    setLoading: (loading: boolean) => void,
    isLogged: (logged: boolean) => void,
    navigate: any
) => {
    isLogged(false)
    setLoading(true)
    if (user && password) {
        const fmData = new FormData();
        fmData.append("user", user)
        fmData.append("password", password)
        const requestOptions = {
            method: 'POST',
            body: fmData
        };
        fetchApi(`${api}/user/login`, requestOptions).then((data) => {
            if (data) {
                localStorage.setItem("user", user)
                localStorage.setItem("token", data)
                isLogged(true)
                navigate('/app')
            }
            setLoading(false);
        })

    }
}
