import React from 'react';
import Criterio from "./Criterio";

export default function Rubrica(props: {
    rubrica: any,
    setRubData: any
}) {
    return (
        <>
            {['COGNITIVO', 'ACTITUDINAL', 'COMUNICATIVO', 'PENSAMIENTO CRÍTICO', 'COLAVORATIVO', 'PLAGIO'].map((crt) => (
                props.rubrica[crt] && <Criterio
                    data={props.rubrica[crt]}
                    title={crt}
                    key={crt}
                    setRubData={props.setRubData}
                />

            ))}


        </>
    );
};
